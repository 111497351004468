import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import {FooterAmbitX, MenuAmbitX} from "./assets/components/NavigationAmbitX";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <MenuAmbitX></MenuAmbitX>
            <App />
            <FooterAmbitX></FooterAmbitX>
        </BrowserRouter>
    </React.StrictMode>
);
