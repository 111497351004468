import React from 'react';
import {
    Routes,
    Route, BrowserRouter, Router
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import CareerPage from './pages/Career.js';
import AbstractPage from './pages/Abstract.js';

import {MenuAmbitX, FooterAmbitX} from "./assets/components/NavigationAmbitX";

function App() {
  return (
          <Routes>
              <Route path="/" element={<IndexPage />} />

              <Route path="/career" element={<CareerPage />} />

              <Route path="/abstract" element={<AbstractPage />} />
          </Routes>
  );
}

export default App;
