import React, {useEffect, useRef, useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

function Icon({ id, open }) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
  );
}
export function AccordionFaq() {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
      <>
        <Accordion className={"block px-12 py-8 bg-gradient-radial-dark rounded-5xl"} open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader className={"text-2xl text-white tracking-2xl"} onClick={() => handleOpen(1)}>What makes AmbitX different from other trading platforms & how does AI play a role in this difference?</AccordionHeader>
          <AccordionBody className={"text-1xl text-blueGray-200 tracking-2xl"}>
            AmbitX sets itself apart from other trading platforms by incorporating cutting-edge artificial intelligence (AI) technology to offer personalized insights, advanced risk management, predictive trading capabilities, and real-time support, alongside pioneering the concept of revenue sharing, thus establishing a new standard in the industry.
          </AccordionBody>
        </Accordion>
        <Accordion className={"block px-12 py-8 bg-gradient-radial-dark rounded-5xl"} open={open === 5} icon={<Icon id={5} open={open} />}>
          <AccordionHeader className={"text-2xl text-white tracking-2xl"} onClick={() => handleOpen(5)}>Where can I purchase $BITX?</AccordionHeader>
          <AccordionBody className={"text-1xl  text-blueGray-200 tracking-2xl"}>
            $BITX token is not yet available. If you're interested in being whitelisted, please sign up on our website.
          </AccordionBody>
        </Accordion>
        <Accordion className={"block px-12 py-8 bg-gradient-radial-dark rounded-5xl"} open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader className={"text-2xl text-white tracking-2xl"} onClick={() => handleOpen(2)}>Will AmbitX Platform offer a mobile app for trading on the go?</AccordionHeader>
          <AccordionBody className={"text-1xl text-blueGray-200 tracking-2xl"}>
            AmbitX Platform will offer a mobile app for traders to conveniently engage in trading activities while on the go.
          </AccordionBody>
        </Accordion>
        <Accordion className={"block px-12 py-8 bg-gradient-radial-dark rounded-5xl"} open={open === 3} icon={<Icon id={3} open={open} />}>
          <AccordionHeader className={"text-2xl text-white tracking-2xl"} onClick={() => handleOpen(3)}>What security measures does AmbitX Platform employ to protect user accounts and assets?</AccordionHeader>
          <AccordionBody className={"text-1xl text-blueGray-200 tracking-2xl"}>
            AmbitX Platform employs a multi-layered approach to security to safeguard user accounts and assets. This includes robust encryption protocols, two-factor authentication (2FA), cold storage for the majority of user funds, regular security audits, and continuous monitoring for suspicious activities. Additionally, AmbitX Platform adheres to industry best practices and complies with relevant regulatory requirements to ensure the highest level of security for its users.
          </AccordionBody>
        </Accordion>
        <Accordion className={"block px-12 py-8 bg-gradient-radial-dark rounded-5xl"} open={open === 4} icon={<Icon id={4} open={open} />}>
          <AccordionHeader className={"text-2xl text-white tracking-2xl"} onClick={() => handleOpen(4)}>How can I sign up for updates or newsletters to stay informed about AmbitX Platform's progress?</AccordionHeader>
          <AccordionBody className={"text-1xl text-blueGray-200 tracking-2xl"}>
            To stay informed about AmbitX Platform's progress, sign up for updates and newsletters on our website. Additionally, follow us on social media platforms such as Twitter, Facebook, LinkedIn, and Telegram, where we'll share all updates and announcements with our community.
          </AccordionBody>
        </Accordion>
      </>
  );
}

export default function Index() {
  const [isOpenSignUp, toggleSignUp] = useState(false);
  const [signUpOk, submitFormSignUp] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function toggle() {

    toggleSignUp((isOpenSignUp) => !isOpenSignUp);
  }

  const timer = setTimeout(() => { submitFormSignUp(false) }, 3500);

  const handleSignUpSubmit = ( boolean) => {
    console.log(email + ' / ' + password + ' / ' + fullName)
    toggle()
    submitFormSignUp((signUpOk) => boolean)
  }

  function useOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleSignUp()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const refSignUp = useRef(null);
  useOutside(refSignUp);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>

        <section>
          <div className='relative pb-24 mt-10 pt-24'>
            <div className='relative z-10 container px-4 mx-auto mt-20'>
              <div className='mb-24 text-center md:max-w-4xl mx-auto'>
                <h1 className='text-white tracking-tighter lg:text-5xl'>
                  Empowering not just transactions, but the thrill of your
                  trading expirence
                </h1>
                <p className='mb-10 text-lg text-white md:max-w-sm mx-auto'>
                  Trade smarter
                </p>
                <div className='flex flex-wrap justify-center -m-2'>
                  <div className='w-auto p-2'>
                    <a
                        className='inline-block px-8 py-4 tracking-tighter border-2 border-orange-400 bg-orange-400 hover:bg-orange-500 text-black focus:ring-4 focus:ring-orange-500 focus:ring-opacity-40 rounded-full transition duration-300'
                        href='#'
                    >
                      $BITX
                    </a>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                        className='inline-block px-8 py-4 text-white hover:text-black tracking-tighter hover:bg-orange-400 border-2 border-white focus:border-orange-400 focus:border-opacity-40 hover:border-orange-400 focus:ring-4 focus:ring-orange-400 focus:ring-opacity-40 rounded-full transition duration-300'
                        onClick={toggle}
                    >
                      Get started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='py-16 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <span className='inline-block mb-8 text-sm text-orange-400 font-medium tracking-tighter'>
                Industry stats
              </span>
              <div className='flex flex-wrap -m-11'>
                <div className='w-full md:w-1/2 lg:w-1/4 p-11'>
                  <div className='text-center'>
                    <h3 className='mb-1.5 text-7xl text-white tracking-4xl'>
                      $165B+
                    </h3>
                    <p className='text-gray-300'>Daily volume</p>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/4 p-11'>
                  <div className='text-center'>
                    <h3 className='mb-1.5 text-7xl text-white tracking-4xl'>
                      2.2m+
                    </h3>
                    <p className='text-gray-300'>Assets</p>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/4 p-11'>
                  <div className='text-center'>
                    <h3 className='mb-1.5 text-7xl text-white tracking-4xl'>
                      420m+
                    </h3>
                    <p className='text-gray-300'>Active users</p>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/4 p-11'>
                  <div className='text-center'>
                    <h3 className='mb-1.5 text-7xl text-white tracking-4xl'>
                      4.1%
                    </h3>
                    <p className='text-gray-300'>Adoption rate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='md:max-w-xl text-center mx-auto'>
              <span className='inline-block text-sm text-orange-400 font-medium tracking-tighter mb-8'>
                Features
              </span>
            </div>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    BitxWallet
                  </h3>
                  <p className='mb-20 text-white'>
                    Experience the freedom of self-custody—optional control for
                    added security and peace of mind.
                  </p>
                  <a className='inline-block' href='#'>
                    <svg
                      width={26}
                      height={26}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.1689 2L2.11268 24'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24.1689 22.1732V2H3.94413'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Order Precision
                  </h3>
                  <p className='mb-20 text-white'>
                    Execute complex trades with precision using diverse advanced
                    order types for enhanced trading strategies
                  </p>
                  <a className='inline-block' href='#'>
                    <svg
                      width={26}
                      height={26}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.1689 2L2.11268 24'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24.1689 22.1732V2H3.94413'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Ai Assistant
                  </h3>
                  <p className='mb-20 text-white'>
                    Seamless integration of ChatGPT with the AmbitX database
                    delivers personalized user support and insightful guidance.
                  </p>
                  <a className='inline-block' href='#'>
                    <svg
                      width={26}
                      height={26}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.1689 2L2.11268 24'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24.1689 22.1732V2H3.94413'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    BITX Card
                  </h3>
                  <p className='mb-20 text-white'>
                    The MBIT Card, powered by our trusted partner, offers
                    convenient access to your crypto assets.
                  </p>
                  <a className='inline-block' href='#'>
                    <svg
                      width={26}
                      height={26}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.1689 2L2.11268 24'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24.1689 22.1732V2H3.94413'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Intuitive Interface
                  </h3>
                  <p className='mb-20 text-white'>
                    Breaking away from traditional screens for a refreshing
                    trading perspective.
                  </p>
                  <a className='inline-block' href='#'>
                    <svg
                      width={26}
                      height={26}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.1689 2L2.11268 24'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24.1689 22.1732V2H3.94413'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='px-10 pt-14 pb-12 h-full bg-gradient-radial-dark border border-gray-900 border-opacity-30 rounded-3xl'>
                  <h3 className='mb-6 text-4xl text-white tracking-tighter-xl'>
                    Pricing Options
                  </h3>
                  <p className='mb-20 text-white'>
                    Pricing plans offer users the flexibility to choose between
                    per-trade fees or subscription-based models for execution.
                  </p>
                  <a className='inline-block' href='#'>
                    <svg
                      width={26}
                      height={26}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.1689 2L2.11268 24'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M24.1689 22.1732V2H3.94413'
                        stroke='white'
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mb-6 md:max-w-2xl text-center mx-auto'>
              <span className='inline-block text-sm text-orange-400 font-medium tracking-tighter'>
                Partners &amp; Integrations
              </span>
            </div>
            <p
              className='mb-10 text-gray-300 text-center'
              contentEditable='false'
            />

            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap items-center justify-center -m-6'>
                <div className='w-auto p-10'>
                  <img
                    src='images/redot-active-png-1710549564182.webp'
                    alt='Redot Card'
                  />
                </div>
                <div className='w-auto p-10'>
                  <img
                    src='images/latest-coingecko-logo-with-text-biw-logo-with-dark-text-copy-svg-1710722310361-webp-1710722434903.webp'
                    alt='Coingecko Api'
                  />
                </div>
                <div className='w-auto p-10'>
                  <img
                    src='images/dextool-active-png-1710552655774.webp'
                    alt='Dextool'
                  />
                </div>
                <div className='w-auto p-10'>
                  <img
                    src='images/aws-active-png-1710550840913.webp'
                    alt='Powered By Amazon Web Services'
                  />
                </div>
                <div className='w-auto p-10'>
                  <img
                    src='images/icons8-chatgpt-76.png'
                    alt='Powered By ChatGPT 4'
                  />
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className='py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='-mb-4 md:max-w-2xl text-center mx-auto'>
              <span className='inline-block text-sm text-orange-400 font-medium tracking-tighter'>
                FAQ
              </span>
            </div>
              <div className='flex flex-wrap -m-1.5'>
                <div className='w-full p-1.5'>
                  <AccordionFaq></AccordionFaq>
                </div>
              </div>
            </div>
        </section>

        {isOpenSignUp &&
         <section className="z-40 fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-90" >
           <div className="transition-opacity duration-3000 p-4 max-w-xl w-full" ref={refSignUp}>
            <div className="relative px-7 pt-10 pb-8 bg-gray-900 bg-opacity-70 max-w-lg mx-auto rounded-5xl">
              <div className="text-center mx-auto">
                  <h3 className="mb-10 text-3xl font-medium text-white tracking-5xl">Lets Get Started</h3>
                  <div className="mb-2 border border-gray-200 focus-within:border-white overflow-hidden rounded-3xl">
                    <input
                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent "
                        type="text" placeholder="Full name" required={true} onChange={(e) => setFullName(e.target.value)}/>
                  </div>
                  <div className="mb-2 border border-gray-200 focus-within:border-white overflow-hidden rounded-3xl">
                    <input
                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent"
                        type="email" placeholder="Enter your email" required={true} onChange={(e) => setEmail(e.target.value)}/>
                  </div>
                  <a className="block mb-10 px-14 py-4 text-center font-medium tracking-2xl border-2 border-orange-400 bg-orange-400 hover:bg-orange-500 text-black focus:ring-4 focus:ring-orange-500 focus:ring-opacity-40 rounded-full transition duration-300"
                     onClick={() => handleSignUpSubmit( true)}>Sign up</a>
                  <div className="flex flex-wrap items-center mb-8">
                    <div className="flex-1 bg-gray-900">
                      <div className="h-px"></div>
                    </div>
                    <div className="px-5 text-xs text-gray-300 font-medium">or sign up with email</div>
                    <div className="flex-1 bg-gray-900">
                      <div className="h-px"></div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -1 mb-7">
                    <div className="w-full p-1">
                      <a className="p-5 flex flex-wrap justify-center bg-gray-900 hover:bg-gray-900 bg-opacity-30 hover:bg-opacity-10 rounded-full transition duration-300"
                         href="#">
                        <div className="mr-4 inline-block">
                          <img src="nightsable-assets/images/sign-in/google.svg" alt=""/>
                        </div>
                        <span className="text-sm text-white font-medium">Sign up with Google</span>
                      </a>
                    </div>
                  </div>
              </div>
              <a className="absolute top-6 right-6" href="#" onClick={toggle}>
                <img src="nightsable-assets/images/modals/close-icon.png"/>
              </a>
            </div>
          </div>
        </section> }

        { signUpOk && <div className="transition-opacity z-50 fixed left-0 bottom-0 w-full pb-10 px-4">
          <div className="flex items-center bg-opacity-60 p-4 px-9 max-w-max mx-auto rounded-full bg-green-500" >
            <img className="mr-4" src="nightsable-assets/images/toast/check-icon.svg" alt=""/>
              <span className="text-white">Your settings have been saved</span>
          </div>
        </div> }
      </>
    </React.Fragment>
  );
}

