import React, {useState} from "react";
import {Link} from "react-router-dom";

export function FooterAmbitX() {
    return (
        <>
            <section className='py-24 overflow-hidden'>
                <div className='container px-4 mx-auto'>
                    <div className='flex flex-wrap -m-8 mb-16'></div>
                    <div className='flex flex-wrap items-center justify-between -m-4'>
                        <div className='w-auto p-4'>
                            <div className='flex flex-wrap -m-4'>
                                <div className='w-auto p-4'>
                                    <img
                                        src='images/logo-bg-black-120px.png'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-auto p-4'>
                            <ul className='flex flex-wrap -m-5'>
                                <li className='p-5'>
                                    <a
                                        className='inline-block font-medium text-gray-300 hover:text-white transition duration-300'
                                    >
                                        English (US)
                                    </a>
                                </li>

                                <li className='p-5'>
                                    <Link
                                        className='inline-block font-medium text-gray-300 hover:text-white transition duration-300'
                                        to='/abstract'
                                    >
                                        Abstract
                                    </Link>
                                </li>
                                <li className='p-5'>
                                    <a
                                        className='inline-block font-medium text-gray-300 hover:text-white transition duration-300'
                                        target='_blank'  href='https://ambitx-platform.gitbook.io/whitepaper'
                                    >
                                        Whitepaper
                                    </a>
                                </li>
                                <li className='p-5'>
                                    <a
                                        className='inline-block font-medium text-gray-300 hover:text-white transition duration-300'
                                        target='_blank' href='https://ambitx-platform.gitbook.io/roadmap-v0.0'
                                    >
                                        Roadmap
                                    </a>
                                </li>
                                <li className='p-5'>
                                    <Link
                                        className='inline-block font-medium text-gray-300 hover:text-white transition duration-300'
                                        to='/career'
                                    >
                                        Career
                                    </Link>
                                </li>
                                <li className='p-5'>
                                    <a
                                        className='inline-block font-medium text-gray-300 hover:text-white transition duration-300'
                                        href='#'
                                    >
                                        Terms
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='w-auto p-4'>
                            <p className='font-medium text-white text-opacity-60'>
                                © 2024 AmbitX
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export function MenuAmbitX() {
    const [isOpen, setIsOpen] = useState(false);

    function toggle() {
        setIsOpen((isOpen) => !isOpen);
    }
    return (
        <>
            <section>
                <div className='container px-4 mx-auto'>
                    <div className='flex items-center justify-between py-5'>
                        <div className='w-auto'>
                            <div className='flex flex-wrap items-center'>
                                <div className='w-auto'>
                                    <Link className='relative z-10 inline-block' to='/'>
                                        <img
                                            src='images/logo-bg-black-120px.png'
                                            alt=''
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='w-auto'>
                            <div className='flex flex-wrap items-center -m-2 float-right'>
                                <div className='w-auto hidden lg:block p-2'>
                                    <ul className='flex items-center float-right'>
                                        <li className='mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                            <Link to='/abstract'>Abstract</Link>
                                        </li>
                                        <li className='mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                            <a target='_blank' href='https://ambitx-platform.gitbook.io/whitepaper'>Whitepaper</a>
                                        </li>
                                        <li className='mr-12 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                            <a target='_blank' href='https://ambitx-platform.gitbook.io/roadmap-v0.0'>Roadmap</a>
                                        </li>
                                        <li className='text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                            <Link to='/career'>Career</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='w-auto lg:hidden p-2'>
                                    <a className='relative z-10 inline-block' onClick={toggle}>
                                        <svg
                                            className='navbar-burger text-orange-500'
                                            width={51}
                                            height={51}
                                            viewBox='0 0 56 56'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <rect
                                                width={56}
                                                height={56}
                                                rx={28}
                                                fill='currentColor'
                                            />
                                            <path
                                                d='M37 32H19M37 24H19'
                                                stroke='black'
                                                strokeWidth='1.5'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { isOpen &&  <div className='navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
                    <div className='navbar-backdrop fixed inset-0 bg-black opacity-80' />
                    <nav className='relative z-10 px-9 pt-8 h-full bg-black overflow-y-auto'>
                        <div className='flex flex-wrap justify-between h-full'>
                            <div className='w-full'>
                                <div className='flex items-center justify-between -m-2'>
                                    <div className='w-auto p-2'>
                                        <Link className='inline-block' to='/'>
                                            <img src='images/logo-bg-black-120px.png' alt='' />
                                        </Link>
                                    </div>
                                    <div className='w-auto p-2'>
                                        <a
                                            className='navbar-burger inline-block text-white'
                                            onClick={toggle}
                                        >
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    d='M6 18L18 6M6 6L18 18'
                                                    stroke='currentColor'
                                                    strokeWidth={2}
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col justify-center py-16 w-full'>
                                <ul>
                                    <li className='mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                        <Link to='/abstract'>Abstract</Link>
                                    </li>
                                    <li className='mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                        <a target='_blank' href='https://ambitx-platform.gitbook.io/whitepaper'>Whitepaper</a>
                                    </li>
                                    <li className='mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                        <a target='_blank' href='https://ambitx-platform.gitbook.io/roadmap-v0.0'>Roadmap</a>
                                    </li>
                                    <li className='text-white font-medium hover:text-opacity-90 tracking-tighter'>
                                        <Link to='/career'>Career</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div> }
            </section>
        </>
    );

}