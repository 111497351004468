import React, {useEffect, useRef, useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'AmbitX\u0020career',
  meta: [],
  link: [],
  style: [],
  script: [],
};



export default function Career() {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState('');

  function useOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //toggle('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function toggle(input) {
    console.log(position)
    setIsOpen((isOpen) => !isOpen);
    setPosition((position) => input)
  }

  const abcd = useRef(null);
  useOutside(abcd);

  function applyJob() {

  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        { isOpen &&
        <section className="z-40 fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-30" >
          <div className="p-4 max-w-xl w-full" ref={abcd}>
            <div className="relative p-6 py-11 bg-blueGray-900 bg-opacity-70 rounded-5xl">
              <h3 className="mb-4 text-1xl font-medium text-white text-center tracking-3xl">Contact</h3>
              <h3 className="ml-4 mb-4 text-orange-400 text-1xl font-medium tracking-3xl">{position}</h3>
              <div className="flex flex-wrap -m-2">
                <div className="w-full p-2">
                  <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                    <div
                        className="relative border border-gray-900 focus-within:border-white overflow-hidden rounded-2xl">
                    <input className="px-6 py-4 pb-2.5 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent"
                           type="text" placeholder="First name"/>
                    </div>
                  </div>
                  <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                    <input className="px-6 py-4 pb-2.5 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent"
                           type="text" placeholder="Last Name"/>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap -m-2">
                <div className="w-full p-2">
                  <div
                      className="relative border border-gray-900 focus-within:border-white overflow-hidden rounded-2xl">
                    <input
                        className="px-6 py-4 pb-2.5 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent"
                        type="text" placeholder="Email"/>
                  </div>
                </div>
                <div className="w-full p-2">
                  <div>
                    <textarea
                        className="mb-3 w-full px-6 py-4 text-gray-300 placeholder-gray-300 bg-transparent border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl resize-none"
                        placeholder="Description" rows="9"></textarea>

                  </div>
                </div>
                <div className="w-full p-2">
                  <div className="flex flex-wrap pt-2 -m-3">
                    <div className="w-full p-3"><a
                        className="block px-14 py-4 text-center font-medium tracking-2xl border-2 border-orange-400 bg-orange-400 hover:bg-orange-500 text-black focus:ring-4 focus:ring-orange-500 focus:ring-opacity-40 rounded-full transition duration-300"
                        onClick={applyJob}>Message</a></div>
                  </div>
                </div>
              </div>
              <a className="absolute top-6 right-6" href="#">
                <img src="nightsable-assets/images/modals/close-icon.png" onClick={() => toggle('')}/>
              </a>
            </div>
          </div>
        </section> }
        <section className='relative mt-10 py-20 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mb-10 md:max-w-xl text-center mx-auto'>
              <span className='inline-block mb-4 text-sm text-orange-400 font-medium tracking-tighter'>
                Open positions
              </span>
            </div>
            <div className='flex flex-nowrap justify-center mb-16 '>
              <div className='relative w-ac mr-20 flex-shrink-0 text-center px-11 py-14 overflow-hidden border border-gray-900 rounded-5xl'>
                <a
                  className='inline-block mb-4 py-3 px-4 text-xs text-orange-400 hover:text-black font-medium hover:bg-orange-400 uppercase border-2 border-orange-400 rounded-full transition duration-300'
                >
                  Marketing
                </a>
                <a className='group block mb-40 max-w-xs mx-auto' onClick={() => toggle('#4 - Marketing Lead')}>
                  <h3 className='text-3xl text-white tracking-3xl group-hover:underline'>
                    Marketing Lead
                  </h3>
                </a>
                <ul className='relative z-10 flex flex-wrap justify-center -m-2'>
                  <li className='flex flex-wrap items-center p-2'>
                    <span className='text-white'>Full Time</span>
                  </li>
                </ul>
                <img
                  className='absolute bottom-0 right-0'
                  src='nightsable-assets/images/career/shadow.svg'
                  alt=''
                />
              </div>
              <div className='relative w-ac mr-20 flex-shrink-0 text-center px-11 py-14 overflow-hidden border border-gray-900 rounded-5xl'>
                <a
                  className='inline-block mb-4 py-3 px-4 text-xs text-orange-400 hover:text-black font-medium hover:bg-orange-400 uppercase border-2 border-orange-400 rounded-full transition duration-300'
                >
                  UX
                </a>
                <a className='group block mb-40 max-w-xs mx-auto' onClick={() => toggle('#5 - Senior Product Designer')}>
                  <h3 className='text-3xl text-white tracking-3xl group-hover:underline'>
                    Product Designer
                  </h3>
                </a>
                <ul className='relative z-10 flex flex-wrap justify-center -m-2'>
                  <li className='flex flex-wrap items-center p-2'>
                    <span className='text-white'>Full Time</span>
                  </li>
                </ul>
                <img
                  className='absolute bottom-0 right-0'
                  src='nightsable-assets/images/career/shadow.svg'
                  alt=''
                />
              </div>
              <div className='relative w-ac flex-shrink-0 text-center px-11 py-14 overflow-hidden border border-gray-900 rounded-5xl'>
                <a
                    className='inline-block mb-4 py-3 px-4 text-xs text-orange-400 hover:text-black font-medium hover:bg-orange-400 uppercase border-2 border-orange-400 rounded-full transition duration-300'
                >
                  Social
                </a>
                <a className='group block mb-40 max-w-xs mx-auto' onClick={() => toggle('#6 - Community Moderator')}>
                  <h3 className='text-3xl text-white tracking-3xl group-hover:underline'>
                    Community Moderator
                  </h3>
                </a>
                <ul className='relative z-10 flex flex-wrap justify-center -m-2'>
                  <li className='flex flex-wrap items-center p-2'>
                    <span className='text-white'>Part Time</span>
                  </li>
                </ul>
                <img
                    className='absolute bottom-0 right-0'
                    src='nightsable-assets/images/career/shadow.svg'
                    alt=''
                />
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

